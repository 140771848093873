<template>
  <!-- <div id="sidebar" :class="{ 'sidebar-show': showSidebar }"> -->
  <div id="sidebar" :class="{ 'sidebar-show': showSidebar }">
    <div class="btn btn_burger"  :class="{ 'btn_burger-show': showSidebar }" @click="showSidebar = !showSidebar">
      <svg v-if="!showSidebar" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
        <rect x="0" y="277.333" width="384" height="42.667"/>
        <rect x="0" y="170.667" width="384" height="42.667"/>
        <rect x="0" y="64" width="384" height="42.667"/>
      </svg>
      <Close v-else />
    </div>
    <div class="header" :class="{ 'header-show': showSidebar }">
      <a hhrf="https://n5bank.com">
        <LogoWhite class="logo"/>
      </a>
    </div>
    <div class="main" :class="{ 'main-show': showSidebar }">
      <div class="avatar">
        <div class="circle">
          <!--<svg>
            <circle class="bg" cx="100" cy="100" r="94"></circle>
            <circle class="progress" cx="100" cy="100" r="94"
              :style="{ 'stroke-dasharray': `${strokePosition}, 590` }"
            ></circle>
          </svg>-->
          <!--<div class="spiner" :style="{ 'transform': `rotate(${spinerRotate}deg)` }">
            <div
              class="btn"
              :style="{ 'transform': `rotate(${360 - spinerRotate}deg)` }"
              @click="showRateInfo = !showRateInfo"
            >
              <div class="arrow-wrapper" :style="{ 'transform': `rotate(${90 + spinerRotate}deg)` }">
                <ArrowFlat v-show="showRateInfo" class="arrow"/>
              </div>
            </div>
          </div>-->
          <div v-show="!showRateInfo" class="img" :style="{ 'background-image': 'url(' + logo + ')' }"></div>
          <!--<div v-show="showRateInfo" class="rateInfo">
            <div class="rateInfo-top">{{ rate }}%</div>
            <div class="rateInfo-bottom">KYB COMPLIANCE RATE</div>
          </div>-->
        </div>
        <div class="desc">
          <router-link :to="{name: 'Settings'}" class="profile-name">
            Hello, {{ ($store.state.user) ? $store.state.user.company_full_name : '' }}
          </router-link>
        </div>
        <router-link :to="{ name: 'Balance' }" class="btn btn_balance">
          Balance: {{ number_format(balance, 2, '.', ' ') }}€
        </router-link>
      </div>
      <div class="links">
        <div class="links__wrapper">
          <div class="bg"/>
          <router-link
            v-for="(item, key) in mainLinks"
            :key="key"
            :to="{ name: item.routeName }"
            class="item"
            :class="{
              'item-active': $route.name == item.routeName || isChildRoute($route.name, item.routeName),
              'soon': item.сomingSoon,
              'special': item.class && item.class.includes('special'),
              'green': item.class && item.class.includes('green'),
              'orange': item.class && item.class.includes('orange')
            }"
            @click.native="showSidebar = false"
          >
            <component :is="item.imgComponent" class="img"></component>
            <div class="desc">{{ item.desc }}</div>
            <span class="soon_label">Coming soon</span>
          </router-link>
        </div>
        <div class="links__wrapper">
          <router-link
            v-for="(item, key) in moreLinks"
            :key="key"
            :to="{ name: item.routeName }"
            class="item"
            :class="{
              'item-active': $route.name == item.routeName,
              'soon': item.сomingSoon
            }"
            @click.native="showSidebar = false"
          >
            <component :is="item.imgComponent" class="img"></component>
            <div class="desc">{{ item.desc }}</div>
            <span class="soon_label">Coming soon</span>
          </router-link>
        </div>
      </div>
      <!--<div class="banner">
        <span class="dots dots-top"><Dots/></span>
        <span class="dots dots-bottom"><Dots/></span>
        <div class="content">
          <div class="h1">Buy & Sell NET</div>
          <div class="h2">License</div>
        </div>
        <router-link :to="{ name: 'Buy & Sell Ready License' }" class="btn"><Arrow class="img"/></router-link>
        <!-- <div class="btn" @click="openChat"><Arrow class="img"/></div>
      </div>-->
    </div>
    <div class="footer" :class="{ 'footer-show': showSidebar }">
      <p class="">
        © 2024 All Rights Reserved
        <a class="terms-service-link" target="_blank" href="https://n5bank.com/docs/terms.pdf">Terms and Conditions</a>
        |
        <a class="terms-service-link" target="_blank" href="https://n5bank.com/docs/privacy.pdf">Privacy Notice</a>
        |
        <a class="terms-service-link" target="_blank" href="https://n5bank.com/docs/confidentiality.pdf">Confidentiality and Non-disclosure Notice</a>
        <!--<span class="payment-images">
          <img class="footer-mastercard" src="/img/mastercard.svg" />
          <img class="footer-visa" src="/img/visa.png" />
        </span>-->
      </p>
      <!-- {{ $store.getters.contentArr.all_rights_reserved }} -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LogoWhite from '@/components/img/LogoWhite.vue'
import Employment from '@/components/img/Employment.vue'
import Calendar from '@/components/img/Calendar.vue'
import Bank from '@/components/img/Bank.vue'
import BankGreen from '@/components/img/BankGreen.vue'
import BankOrange from '@/components/img/BankOrange.vue'
import Contract from '@/components/img/Contract.vue'
import Briefcase from '@/components/img/Briefcase.vue'
import StampDocument from '@/components/img/StampDocument.vue'
import Atlas from '@/components/img/Atlas.vue'
import PaymentModule from '@/components/img/PaymentModule.vue'
import Arrow from '@/components/img/Arrow.vue'
import Dots from '@/components/img/Dots.vue'
import ArrowFlat from '@/components/img/ArrowFlat.vue'
import Close from '@/components/img/Close.vue'

export default {
  components: {
    LogoWhite,
    Employment,
    Calendar,
    Bank,
    Contract,
    Briefcase,
    StampDocument,
    Atlas,
    PaymentModule,
    Arrow,
    Dots,
    ArrowFlat,
    Close,
    BankGreen,
    BankOrange,
  },
  data: () => ({
    logo: '',
    showRateInfo: false,
    showSidebar: false,
    mainLinks: [
      {
        routeName: 'YouOffers',
        imgComponent: 'Employment',
        desc: 'Your Requests',
      },
      {
        routeName: 'Buy & Sell Ready License',
        imgComponent: 'BankGreen',
        desc: 'Buy Ready Licenses',
        class: 'special green',
      },
      {
        routeName: 'Self portfolio',
        imgComponent: 'BankOrange',
        desc: 'Sell License',
        class: 'orange',
      },
      {
        routeName: 'Request',
        imgComponent: 'Calendar',
        desc: 'Create Bank',
      },
      {
        routeName: 'Compliance',
        imgComponent: 'Contract',
        desc: 'Compliance',
      },
      /*{
        routeName: 'Software Net',
        imgComponent: 'Briefcase',
        desc: 'Software Service',
      },*/
      {
        routeName: 'Legal Atlas',
        imgComponent: 'StampDocument',
        desc: 'Legal Service',
      },
    ],
    moreLinks: [
      /*{
        routeName: 'Offer',
        imgComponent: 'Bank',
        desc: 'Your Offer',
      },*/
      /*{
        routeName: 'Accounts',
        imgComponent: 'Briefcase',
        desc: 'Accounts',
      },
      {
        routeName: 'Payment Atlas',
        imgComponent: 'Atlas',
        desc: 'Payment Net',
        сomingSoon: true
      },
      {
        routeName: 'Payment Module',
        imgComponent: 'PaymentModule',
        desc: 'Payment Module',
        сomingSoon: true
      },*/
    ]
  }),
  computed: {
    ...mapState ({
      rate: state => state.userKybRate
    }),
    strokePosition () {
      return 590 / 100 * this.rate
    },
    spinerRotate () {
      return 360 / 100 * this.rate
    },
    balance () {
      return (this.$store.state.user) ? this.$store.state.user.balance : 0
    },
  },
  methods: {
    ...mapActions ({
      getKybRate: 'getUserKybRate'
    }),
    openChat () {
      Tawk_API.maximize()
    },
    isChildRoute(routeName, itemRouteName){
      if(routeName == 'EditRequest' && itemRouteName == 'YouOffers'){
        return true
      }

      return false
    }
  },
  watch: {
    '$store.state.user': function() {
      this.logo = (this.$store.state.user.logo) ? process.env.VUE_APP_BACKEND_URL + 'storage/images/users/logos/origin/' +  this.$store.state.user.logo : '/img/default-avatar.png';
    }
  },
  created () {
    this.getKybRate()
  }
}
</script>

<style lang="scss" scoped>
#sidebar {
  position: fixed;
  width: var(--sb_width);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #011230;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 99;
  &.sidebar-show {
    width: 20rem;
  }
  .profile-name{
    color:#fff;
    text-decoration:none;
  }
  .btn_burger {
    display: none;
    width: 2.75rem;
    height: 2.75rem;
    padding: .25rem;
    margin: 1rem auto;

    @media (max-width: 1280px) {
      display: block;
    }

    ::v-deep svg {
      width: 100%;
      height: 100%;

      rect {
        fill: #fff;
      }
    }

    &-show {
      display: none;
      position: absolute;
      top: -.25rem;
      right: -3.25rem;
      width: 2.5rem;
      height: 2.5rem;
      background: var(--gr_green);
      border-radius: 50%;

      @media (max-width: 1280px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep svg {
        width: 65%;
        height: 65%;

        path {
          fill: #ffffff;
        }
      }
    }
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-height: 900px) {
      padding-top: 1rem;
    }

    @media (max-width: 1280px) {
      display: none;

      &-show {
        display: flex;
      }
    }

    .logo {
      max-width: 150px;
      height: var(--header_height);

      @media (max-height: 900px) {
        height: calc(var(--header_height) - 2rem);
      }

      @media (max-height: 750px) {
        height: calc(var(--header_height) - 3rem);
      }
    }
  }

  .main {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    // overflow-y: auto;

    @media (max-width: 1280px) {
      display: none;

      &-show {
        display: flex;
      }
    }

    .avatar {
      padding: .5rem 1.5rem;
      text-align: center;

      .circle {
        position: relative;
        width: 200px;
        height: 200px;
        margin: 0 auto;

        @media (max-height: 780px) {
          display: none;
        }

        svg {
          width: 200px;
          height: 200px;
        }

        .bg {
          fill: none;
          stroke-width: .75rem;
          stroke: #F0F2F7;
          stroke-opacity: 0.2;
        }
        .progress {
          fill: none;
          stroke-width: .75rem;
          stroke: #fff;
          stroke-linecap: round;
          stroke-dasharray: 1, 590;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        }

        .img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .spiner {
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          transform-origin: right;
          z-index: 1;

          .btn {
            position: absolute;
            top: -0.75rem;
            right: -1.25rem;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background-color: #fff;
            background-image: url('/img/smile.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 65%;

            .arrow-wrapper {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 50%;
              transform-origin: bottom;
              transform: rotate(90deg);

              .arrow {
                position: absolute;
                bottom: 0;
                left: 100%;
                transform: translateY(50%);
                width: .75rem;
                height: .75rem;
              }
            }
          }
        }

        .rateInfo {
          position: absolute;
          top: 52%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 55%;
          height: 55%;

          &-top {
            width: 100%;
            height: 50%;
            text-align: center;
            font-size: 3rem;
            font-weight: 600;
            color: #ffffff;
          }

          &-bottom {
            width: 100%;
            height: 50%;
            color: #fff;
            font-size: 11px;
            text-transform: uppercase;
            text-align: center;
            padding-top: .25rem;
          }
        }
      }

      .desc {
        padding: .25rem 1rem 0;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;

        @media (max-height: 720px) {
          padding-top: 1rem;
        }
      }

      .btn_balance {
        font-size: 1.25rem;
        font-weight: 700;
        background: linear-gradient(113.24deg, #fff 16.01%, #fff 106.71%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #fff;
      }
    }

    .links {
      width: 100%;
      // flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      justify-content: space-evenly;
      padding-bottom: 1rem;

      @media (max-height: 900px) {
        padding-bottom: .25rem;
      }

      &__wrapper {
        position: relative;
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        justify-content: space-evenly;

        .bg {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          //border: 1px solid rgba(255, 255, 255, 0.5);
          //background: linear-gradient(124.81deg, #323940 0%, rgba(50, 57, 64, 0.9) 46.79%, rgba(0, 0, 0, 0.02) 100%);
          //box-shadow:
            //20px 30px 100px rgba(0, 0, 0, 0.05),
            //inset -5px -5px 10px rgba(255, 255, 255, 0.05),
            //inset 5px 5px 10px rgba(255, 255, 255, 0.05);
          backdrop-filter: blur(20px);
          opacity: 0.3;
        }
      }

      .item, a.item {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // padding: .5rem 0 .5rem 2rem;
        padding-left: 2rem;
        color: #ffffff;
        text-decoration: unset;
        &.special{
          background: rgba(255,255,255,.15);
        }
        &.green{
          svg{
            fill: green !important;
            path{
              fill: green !important;
            }
          }
        }

        &:hover {
          //background: linear-gradient(269.97deg, rgba(19, 180, 151, 0.13) 0.01%, rgba(19, 180, 151, 0) 99.96%);
          .desc::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, transparent 0%, #fff 100%);
            z-index: -1;
            //border-radius: 6px 0 0 6px;
          }
        }

        &-active {
         //background: linear-gradient(269.97deg, rgba(19, 180, 151, 0.13) 0.01%, rgba(19, 180, 151, 0) 99.96%);

          .desc::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, transparent 0%, #1763fb 100%);
            z-index: -1;
            //border-radius: 6px 0 0 6px;
          }
        }

        .img {
          width: 1.75rem;
          height: 1.75rem;
          margin: .5rem;
        }

        .desc, a.desc {
          padding: .5rem .75rem;
          padding: 1.4vh .75rem;
          font-weight: 600;
        }

        .soon_label {
          display: none;
        }

        &.soon {
          color: #9F9F9F;

          // &:hover {
          //   background: inherit;
          // }

          .soon_label {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: .25rem;
            right: .75rem;
            color: #9F9F9F;
            background: #22252B;
            border-radius: 6px;
            font-size: 10px;
            padding: 2px 10px;
            opacity: .6;
          }
        }
      }
    }

    .banner {
      position: relative;
      width: 14rem;
      // height: 7rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: var(--boxRadius);
      background: #1763fb;
      padding: 0rem 0.75rem;
      overflow: hidden;
      font-size: 12px;

      @media (max-height: 860px) {
        display: none;
      }

      .dots {
        display: none;
        position: absolute;

        &-top {
          top: -6px;
          left: 1.25rem;
          width: 1.5rem;
        }

        &-bottom {
          bottom: -6px;
          right: 0.5rem;
          width: 1.75rem;
          transform: rotate(90deg);
          opacity: 0.5;
        }
      }

      .content {
        width: 65%;
        font-weight: 600;
        margin: .25rem;
        text-align: center;

        .h2 {
          font-size: 12px;
        }

        @media (max-height: 960px) {
          // font-size: 14px;
          line-height: 1.4;
        }

        // @media (max-height: 880px) {
        //   font-size: 12px;
        // }
      }

      .btn {
        position: relative;
        width: 2.5rem;
        height: 2.5rem;
        margin: .25rem;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .img {
          width: 1.25rem;
        }
      }
    }
  }

  .footer {
    width: 100%;
    text-align: center;
    font-size: .75rem;
    padding: 0.2rem 1.5rem;
    color: #969BA0;
    font-size: 9px;
    @media (max-width: 1280px) {
      font-size: 8px;
    }

    .terms-service-link {
      color: #969BA0;
      text-decoration: none;
    }

    @media (max-width: 1280px) {
      display: none;

      &-show {
        display: flex;
      }
    }
  }
}

img.footer-mastercard {
  height: 10px;
  margin-right: 10px;
}

img.footer-visa {
  height: 10px;
}

.payment-terms-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 5px;
}

.payment-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px 0 0 15px;
}
</style>
