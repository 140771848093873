<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close"/>
    </div>
    <div class="modal__header">
      <div class="sbttl">
        Business Offer Information
      </div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="data">
          <select name="business_type" class="inpt" style="width: 100%;" v-model="form.business_type" @change="checkChanges">
            <option value="null">Select business type</option>
            <option v-for="type in fintechTypes" :key="type.id" :value="type.name">{{ type.name }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="data">
          <select name="license_type" class="inpt" style="width: 100%;" v-model="form.license_type" @change="checkChanges">
            <option value="null">Select license type</option>
            <option v-for="type in licenseTypes" :key="type" :value="type">{{ type }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="data">
          <select class="inpt" style="width: 100%;" v-model="form.country_id" @change="checkChanges">
            <option value="null">Select country</option>
            <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="data">
          <textarea
            name="description"
            rows="5"
            class="inpt inpt__ta"
            v-model="form.description"
            placeholder="Description"
            @input="checkChanges"/>
        </div>
      </div>
      <div class="row row__price">
        <div class="data input-group__col">
          <input
            type="text"
            name="wish_price"
            class="inpt"
            v-model="form.wish_price"
            placeholder="Wish Price"
            @input="checkChanges">
          <select v-if="currencies" v-model="form.currency_id"  @change="checkChanges">
            <option value="null">Select currency</option>
            <option
              v-for="c in currencies" :key="c.id"
              :value="c.id"
              v-text="c.code"
            />
          </select>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div v-if="isChanged" class="btn" @click="submit">Send</div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    allCurrencies: null,
    countries: [],
    form: {
      business_type: null,
      license_type: null,
      country_id: null,
      description: null,
      wish_price: null,
      currency_id: 2
    },
    original_form:{
      business_type: null,
      license_type: null,
      country_id: null,
      description: null,
      wish_price: null,
      currency_id: 2
    },
    licenseTypes: [
        "Crypto/VASP/CASP",
        "Money service/Remittee",
        "API",
        "AEMI",
        "MSO",
        "MSB",
        "MPI",
        "Small Payment Institution",
        "Payment Institution",
        "Small EMI",
        "EMI",
        "SRO",
        "PSP (AISP, PISP)",
        "Bank",
        "VC",
        "Fund",
        "Asset management",
        "Under management",
        "Broker/Dealer/Forex"
    ],
    fintechTypes: [],
    isChanged: false,
    error: ''
  }),
  async created () {
    await this.getCurrencies()
    await this.getCountries()
    await this.getOffer()
    await this.getFintechTypes()
  },
  computed: {
    currencies () {
      if (!this.allCurrencies) return []
      return this.allCurrencies.filter(el => el.code === 'EUR' || el.code === 'USD')
    },
  },
  methods: {
    async getFintechTypes(){
      await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/fintech-types')
        .then(({ data }) => {
          this.fintechTypes = data
        })
        .catch(err => console.dir(err))
    },
    checkChanges() {
      const keysToCheck = ['business_type', 'license_type', 'country_id', 'description', 'wish_price', 'currency_id'];

      this.isChanged = keysToCheck.some(key => this.form[key] != this.original_form[key]);
    },
    async getOffer () {
      const id = this.options.id;

      if(id){
        axios.get(process.env.VUE_APP_BACKEND_API_URL + 'business-offers/' + id)
        .then(({ data }) => {
          this.original_form = JSON.parse(JSON.stringify(data))
          this.form = data
        })
        .catch(err => console.dir(err))
      }
    },
    async getCurrencies () {
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/currencies')
        .then(({ data }) => {
          this.allCurrencies = data
        })
        .catch(err => console.dir(err))
    },
    async getCountries() {
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/countries')
        .then(({ data }) => {
          this.countries = data
        })
        .catch((error) => console.log(error))
    },
    async submit() {
      let success = false
      const id = this.options.id

      let newForm = {
        new_business_type: this.form.business_type,
        new_license_type: this.form.license_type,
        new_country_id: this.form.country_id,
        new_description: this.form.description,
        new_wish_price: this.form.wish_price,
        new_currency_id: this.form.currency_id,
      }

      await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'business-offers/' + id + '/update-requst', newForm)
        .then(({ data }) => {
          if (data) {
            this.close()
            success = true
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })

      this.$noty.success('You successfully send request')
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__header{
    padding: 24px;
    .sbttl{
      font-style: normal !important;
    }
  }
  &__body{
    padding: 0 24px;
  }
  &__wrapper {
    width: 540px;
    max-width: 100%;
  }

  &__close {
    .btn::before {
      content: '+';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      color: white;
      font-size: 2.25rem;
      font-weight: 300;
    }
  }

  &__body {
    flex-direction: column;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      margin: .5rem 0;
      .data {
        display: flex;
        flex: 1 0 auto;
        max-width: 100%;
      }
    }
    input, textarea {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 16px 24px !important;
      position: relative;
      align-self: stretch;
      width: 100%;
      flex: 0 0 auto;
      border-radius: 5px;
      border: 1px solid;
      border-color: #ababb2;
      margin-bottom: 0 !important;
      resize: none;
      &::placeholder {
        color: #09090b;
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder { /* Edge 12 -18 */
        color: #09090b;
      }
    }
    textarea{
      height: 120px;
      padding: 4px 24px !important;
    }

    select {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 4px 24px !important;
      position: relative;
      align-self: stretch;
      width: 100%;
      flex: 0 0 auto;
      border-radius: 5px;
      border: 1px solid;
      border-color: #ababb2;
      margin-bottom: 0 !important;
    }

    .input-group__col{
      gap: 1rem;
      input{
        width: calc(65% - 1rem);
        max-width: calc(65% - 1rem);
      }
      select{
        width: 35%;
        max-width: 35%;
      }
    }

    .label {
      // min-width: 10rem;
      font-weight: bold;
      padding-right: 1rem;
    }

    .data {
      .inpt {
        color: var(--c_black);
        outline: none;
        -webkit-appearance: none;
      }
    }

    .invalid-feedback {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    // .form-control {
    //   width: 100%;
    //   height: 100%;
    //   color: #ffffff;
    //   background-color: #1C2126;
    //   padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    //   margin: auto 0rem;
    //   border: 1px solid #2F363D;
    //   border-radius: 0.75rem;
    //   box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
    //   outline: none;
    //   -webkit-appearance: none;
    // }
  }

  &__footer {
    padding: 24px;
    .btn {
      font-size: 18px;
      padding: 1rem 3rem;
      margin: 0;
      background: #1763fb;
      box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
      border-radius: 6px;
    }
  }
}
</style>
