import Vue from 'vue'
import VueRouter from 'vue-router'
import YouOffers from '../views/YouOffers.vue'
import Notifications from '../views/Notifications.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/login-code',
    name: 'LoginCode',
    component: () => import('../views/LoginCode.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/passwords/ForgotPassword.vue')
  },
  {
    path: '/password/reset/:token',
    name: 'NewPassword',
    component: () => import('../views/passwords/NewPassword.vue')
  },
  {
    path: '/ready-license/:id/:businessType?/:businessType?',
    name: 'PublicReadyLicenses',
    component: () => import('../views/PublicLicenseList.vue'),
  },
  {
    path: '/',
    name: 'YouOffers',
    component: YouOffers,
    meta: {
      requiresAuth: true,
      desc: 'Your Offer'
    }
  },
  {
    path: '/new-request',
    name: 'Request',
    component: () => import('../views/NewRequest.vue'),
    meta: {
      requiresAuth: true,
      desc: 'New Request'
    }
  },
  {
    path: '/edit-request/:id',
    name: 'EditRequest',
    component: () => import('../views/EditRequest.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Edit Request'
    }
  },
  /*{
    path: '/offer',
    name: 'Offer',
    component: () => import('../views/Offer.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Your Offer'
    }
  },*/
  {
    path: '/compliance',
    name: 'Compliance',
    component: () => import('../views/Compliance.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Compliance'
    }
  },
  /*{
    path: '/accounts',
    name: 'Accounts',
    props: true,
    component: () => import('../views/Accounts.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Accounts'
    }
  },*/
  {
    path: '/legal_atlas',
    name: 'Legal Atlas',
    component: () => import('../views/LegalAtlas.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Legal Service'
    }
  },
  {
    path: '/payment_atlas',
    name: 'Payment Atlas',
    component: () => import('../views/PaymentAtlas.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Payment Net'
    }
  },
  {
    path: '/payment_module',
    name: 'Payment Module',
    component: () => import('../views/PaymentModule.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Payment Module'
    }
  },
  {
    path: '/balance',
    name: 'Balance',
    component: () => import('../views/Balance.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Balance'
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Settings'
    }
  },
  {
    path: '/ready-licenses',
    name: 'Buy & Sell Ready License',
    component: () => import('../views/BuyOrSellBusiness.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Buy Ready License'
    }
  },
  {
    path: '/bos_business',
    name: 'Old Buy & Sell Ready License',
    component: () => import('../views/BuyOrSellBusiness.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Buy Ready License'
    }
  },
  {
    path: '/sell-license',
    name: 'Self portfolio',
    component: () => import('../views/SelfPortfolio.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Sell License'
    }
  },
  {
    path: '/my_portfolio',
    name: 'Old Self portfolio',
    component: () => import('../views/SelfPortfolio.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Sell License'
    }
  },
  /*{
    path: '/software_net',
    name: 'Software Net',
    component: () => import('../views/SoftwareNet.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Software Service'
    }
  },*/
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/Chat.vue'),
    meta: {
      requiresAuth: true,
      isChat: true
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
        requiresAuth: true,
        headerName: 'Notifications'
      }
  },
  {
    path: '/auth/:provider/callback',
    component: {
      template: '<div class="auth-component"></div>'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  history: true,
  base: process.env.BASE_URL,
  routes
})

export default router
